<template>
	<div>
		<Nav />
    <Breadcrumbs :items="crumbs"/>
		<div class="login">
      <div class="login__main">
        <div class="login__main--head text-center">
          <h1>Account Login</h1>
        </div>
	      <div class="login__main--body">
	      	<form @submit.prevent>
	          <div>
	            <label for="email1">Email</label>
	            <input type="text" placeholder="you@email.com" v-model.trim="loginForm.email" id="email1" />
	          </div>
	          <div>
	            <label for="password1">Password</label>
	            <input type="password" placeholder="******" id="password1" v-model.trim="loginForm.password" />
	          </div>
            <transition name="fadeStop">
              <div v-if="errorMsg" class="error">{{errorMsg}}</div>
            </transition>
            <p>Don't have an account yet? Click <router-link :to="{name: 'register'}">here</router-link> to register.</p>
	          <button class="btn btn__primary mt-3 mb-3" @click="login()">
	            Log In
	            <transition name="fade">
	              <span class="ml-2" v-if="performingRequest">
	              <i class="fa fa-spinner fa-spin"></i>
	              </span>
	            </transition>
	          </button>
	        </form>
	      </div>
	    </div>
		</div>
    <Footer />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
const fb = require('../../firebaseConfig.js')

export default {
  data() {
    return {
    	name: 'login',
      performingRequest: false,
      loginForm: {
        email: '',
        password: ''
      },
      errorMsg: ''
    }
  },
  components: {
    Loader,
    Nav,
    Footer,
    Breadcrumbs
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'customerProfile']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Home", to: { name: "home"}}
      let step2 = { title: "Login", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      return crumbsArray
    },
  },
  methods: {
    login() {
      this.performingRequest = true
      this.errorMsg = ''
      setTimeout(() => {
        fb.auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password).then(user => {
          console.log(user)
          this.$store.commit('setCurrentUser', user.user)
          this.$store.dispatch('fetchUserProfile')
          this.$router.push('/dashboard')
        }).catch(err => {
          console.log(err)
          this.errorMsg = err.message
        })
        this.performingRequest = false
      }, 1000)
    },
  }
}
</script>